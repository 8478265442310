/* FloatingButtons.css */
.floating-buttons {
    position: fixed;
    bottom: 20px;
    right: 20px;
    display: flex;
    flex-direction: row;
    gap: 10px;
    border-color: azure;
}

.floating-button {
    width: 40px; /* Set width for round buttons */
    height: 40px; /* Set height for round buttons */
    background-color: #057945; /* WhatsApp color */
    color: white;
    border-radius: 50%; /* Makes the button round */
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.3);
    transition: background-color 0.3s, transform 0.3s;
}

.floating-button:hover {
    background-color: #dcdede; /* Darker WhatsApp color */
    transform: scale(1.1); /* Slightly enlarge on hover */
}

.call-button {
    background-color: #007bff; /* Call button color */
}

.call-button:hover {
    background-color: #e0e4ea; /* Darker call button color */
}
