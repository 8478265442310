.about__section {
  transition: all 0.3s ease-in-out;
}

.fade-in {
  animation: fadeIn 0.8s ease-in-out;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
    transform: translateY(20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.about__content {
  padding: 20px;
}

.brand-name {
  color: #fd7e14;
  font-weight: 700;
}

.section__subtitle {
  font-size: 1.2rem;
  color: #6c757d;
  letter-spacing: 1px;
  text-transform: uppercase;
}

.section__title {
  font-size: 2.4rem;
  font-weight: bold;
  color: #343a40;
}

.section__description {
  color: #495057;
  line-height: 1.6;
}

.about__features {
  list-style: none;
  padding: 0;
}

.about__features li {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 12px;
  font-size: 1.1rem;
  color: #495057;
}

.feature-icon {
  font-size: 1.5rem;
  color: #28a745;
}

.about__image img {
  transition: transform 0.5s ease;
  height: 400px;
  width: 400px;
  border-radius: 50cm;
  
}

.hover-scale:hover {
  transform: scale(1.05);
}
