/* Service Item Container */
.service__item {
  text-align: center;
  margin-bottom: 2rem;
  background-color:#031675;
  padding-bottom: 10px;
  padding-top: 10px;
  border-width: 2px;
  border-style: solid;
  border-color: #d88f1f;

}

/* Image Box with Icon */
.service__image-box {
  position: relative;
  display: inline-block;
  width: 100%;
  max-width: 300px;
}

.service__image-box img {
  width: 100%;
  height: auto;
  border-radius: 18px; /* Optional: To round the image corners */
}

.service__image-box span {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: rgba(0, 0, 0, 0.5); /* Optional: To darken the background behind the icon */
  padding: 10px;
  border-radius: 50%; /* Makes the icon background circular */
}

.service__image-box span i {
  font-size: 2.5rem;
  color: #f9a826;
}

/* Heading */
.service__item h6 {
  font-size: 1.2rem;
  font-weight: 600;
  transition: 0.3s;
  margin-top: 1rem;
  color: #f9a826;
}

.service__item h6:hover {
  color: #f40b0b;
}

/* Description Text */
.service__description {
  font-size: 1rem;
  color: #fff;
  margin-top: 0.5rem;
 

}



.section__description{
  color: white;
}

/* Book Now Button */
.book-now-btn {
  margin-top: 1rem;
  padding: 10px 20px;
  background-color: #f9a826;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 1rem;
  transition: 0.3s;
}

.book-now-btn:hover {
  background-color: #d88f1f;
}

/* Responsive Adjustments */
@media only screen and (max-width: 768px) {
  .service__item h6 {
    font-size: 1.1rem;
  }
  .service__description {
    font-size: 0.9rem;
  }
  .book-now-btn {
    font-size: 0.9rem;
    padding: 8px 16px;
  }
 
  
}
